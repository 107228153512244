import classNames from 'classnames';
import React from 'react'
import Button from '../Button/Button';
import IconClose from '../IconClose/IconClose';
import NamedLink from '../NamedLink/NamedLink';
import { FaGift } from 'react-icons/fa6';
import css from './Banner.module.css';


export default function Banner() {
  const [openBanner, setOpenBanner] = React.useState(true);
  return (
    <div>
      {openBanner && (
        <div className={classNames(css.header__banner, "position-relative")}>
          <Button
            onClick={() => setOpenBanner(false)}
            rootClassName={css.header__banner__close}
          >
            <IconClose rootClassName={css.closeIcon} />
          </Button>
          <NamedLink
            name="SignupFromBannerPage"
            className={classNames(css.signup_banner, 'd-none d-sm-block')}
          >
            ⚡Limited time: Free delivery for first time renters in Brooklyn
            (expires August 31)⚡
          </NamedLink>
          <NamedLink
            name="SignupFromBannerPage"
            className={classNames(css.signup_banner, 'd-block py-2 d-sm-none w-100')}
          >
            <div className="w-100 gap-4 d-flex align-items-center">
              <div>
                <FaGift size={30} />
              </div>
              <div className="ml-4">
                <p style={{
                  fontSize: '0.9rem',
                }} className="mb-1 h6">Free delivery for first time renters in Brooklyn</p>
                <p className="mb-0" style={{
                  fontSize: '0.8rem',
                }}>Limited time: (expires August 31) ⚡</p>
              </div>
            </div>
          </NamedLink>
        </div>
      )}
    </div>
  )
}
